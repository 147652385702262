<template>
  <div class="ak-blur-page">
    <h3 class="ak-colorText-sandWhite ak-blur-page-message font23-600 mb-5">
      Oops!
    </h3>
    <p class="font-roboto ak-blur-page-message ak-colorText-sandWhite">
      Seite nicht gefunden. Bitte kontaktieren Sie den Support per E-Mail
      <a
        class="font-roboto ak-colorText-sandWhite"
        href="mailto:anaking.gesundheit@zhaw.ch"
      >
        anaking.gesundheit@zhaw.ch
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AKAccessDeniedMessage',
};
</script>

<style lang="scss" scoped>
.ak-blur-page {
  background-color: var(--v-primary-base);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-message {
    font-size: 32px;
    text-shadow: 0 0 6px #000;
    text-align: center;
    width: 80vw;

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 60vw;
    }
  }
}
</style>
